import {action, computed, flow, makeObservable, observable, runInAction} from "mobx";
import {MultiResultLoadingState} from "../types/LoadingState";
import {CpdDocument, CpdDocumentDto, CpdDocumentNew} from "./models/CpdDocument";
import {splitFilename} from "../utils/split-filename";
import {IApiClient} from "../common/api.client";
import {CpdActivityDto, ActivityFilePresignedDto} from "../common/webapicall";
import {uploadFileToS3} from "../utils/s3-uploader";
import {RouteConstants} from "../resources/route-constants";
import {isWebApiErrorResponse} from "../common/webapicall.extensions";
import {AuthStore} from "./authStore";

export type FileUploadResult = {
	activityId: string;
	success: boolean;
	failedFiles: string[];
};

export class CpdSaveActivityStore {
	@observable loadingState: MultiResultLoadingState<"completedCreate" | "completedEdit" | "not-found">;
	@observable isSaving: boolean;

	@observable activityId: string | null;
	@observable activityToEdit: CpdActivityDto | null;

	@observable relatedDocuments: Map<string, CpdDocument>;
	@observable newDocuments: Map<string, CpdDocumentNew>;
	@observable documentIdsToRemove: string[];

	@observable failedFileUploads: string[];

	constructor(private apiClient: IApiClient, private authStore: AuthStore) {
		makeObservable(this);

		this.loadingState = "initial";
		this.initializeFields();
	}

	@computed
	get relatedDocumentsList() {
		return Array.from(this.relatedDocuments.values());
	}

	@computed
	get newDocumentsList() {
		return Array.from(this.newDocuments.values());
	}

	@computed
	get isEditDisabled() {
		return this.isSaving || !(this.loadingState === "completedCreate" || this.loadingState === "completedEdit") || !this.authStore.hasMemberAccess;
	}

	init(mode: "create"): IterableIterator<any>;
	init(mode: "edit", activityId: string): IterableIterator<any>;

	@flow
	*init(mode: "create" | "edit", activityId?: string) {
		this.loadingState = "loading";

		if (mode === "create") {
			this.initCreate();
		} else {
			yield this.initEdit(activityId);
		}
	}

	@action
	private initializeFields() {
		this.isSaving = false;

		this.activityId = null;
		this.activityToEdit = null;

		this.relatedDocuments = new Map();
		this.newDocuments = new Map();
		this.documentIdsToRemove = [];

		this.failedFileUploads = [];
	}

	@action
	private initCreate() {
		this.initializeFields();

		this.loadingState = "completedCreate";
	}

	@flow
	private *initEdit(activityId: string) {
		try {
			this.activityToEdit = yield this.apiClient.cpdActivityClient.getById(activityId);
		} catch (error) {
			if (isWebApiErrorResponse(error) && error.statusCode === 404) {
				this.loadingState = "not-found";
				return;
			} else {
				this.loadingState = "error";
				throw error;
			}
		}

		this.activityId = this.activityToEdit.id;

		for (const file of this.activityToEdit?.files) {
			this.relatedDocuments.set(file.fileName, {
				id: file.fileName,
				filename: file.fileName,
				size: file.fileSize,
				url: file.presignedUrl,
			});
		}
		this.newDocuments = new Map();
		this.loadingState = "completedEdit";
	}

	@action
	emptyFailedFileUploads() {
		this.failedFileUploads = [];
	}

	@action
	unload() {
		this.activityId = null;
		this.activityToEdit = null;

		this.loadingState = "initial";
		this.isSaving = false;
		this.relatedDocuments = new Map();

		this.newDocumentsList.forEach(doc => {
			URL.revokeObjectURL(doc.url);
		});

		this.newDocuments = new Map();
		this.documentIdsToRemove = [];

		this.failedFileUploads = [];
	}

	@flow
	*resetModifications(reset: (activity?: CpdActivityDto | null) => void, {resetToCreate = false}: {resetToCreate?: boolean} = {}) {
		this.isSaving = true;

		this.newDocumentsList.forEach(doc => {
			URL.revokeObjectURL(doc.url);
		});

		this.newDocuments = new Map();
		this.failedFileUploads = [];

		if (resetToCreate || this.loadingState === "completedCreate") {
			yield this.init("create");
		} else if (this.loadingState === "completedEdit") {
			yield this.init("edit", this.activityId);
		}

		reset(this.activityToEdit);

		this.isSaving = false;
	}

	@flow
	*saveActivity(activity: CpdActivityDto, courseId?: string) {
		const filesToSave = [...this.newDocumentsList, ...this.relatedDocumentsList].map(
			doc =>
				({
					fileName: doc.filename,
					fileSize: doc.size,
				} as CpdDocumentDto),
		);

		this.failedFileUploads = [];

		if (this.loadingState === "completedEdit") {
			yield this.saveEditedActivity(activity, this.newDocumentsList, filesToSave);
		} else if (this.loadingState === "completedCreate") {
			yield this.saveCreatedActivity(activity, this.newDocumentsList, filesToSave, courseId);
		} else {
			throw new Error(`Cannot save activity in current state: ${this.loadingState}`);
		}
	}

	@flow
	private *saveCreatedActivity(activity: CpdActivityDto, filesToUpload: CpdDocumentNew[], filesToSave: CpdDocumentDto[], courseId?: string) {
		this.isSaving = true;

		const fileUploadResult: FileUploadResult = yield this.uploadFilesToS3(filesToUpload);

		if (fileUploadResult.success) {
			const activityToCreate = {
				...activity,
				id: fileUploadResult.activityId,
				files: filesToSave.map(f => ({
					fileName: f.fileName,
					fileSize: f.fileSize,
				})),
			};

			try {
				yield this.apiClient.cpdActivityClient.create({
					courseId: courseId,
					activity: activityToCreate,
				});
			} catch (error) {
				throw error;
			} finally {
				this.isSaving = false;
			}

			this.activityToEdit = activity;
			this.activityToEdit.id = this.activityId;
			this.activityId = fileUploadResult.activityId;
		} else {
			this.failedFileUploads = fileUploadResult.failedFiles;
		}

		this.isSaving = false;
	}

	@flow
	private *saveEditedActivity(activity: CpdActivityDto, filesToUpload: CpdDocumentNew[], filesToSave: CpdDocumentDto[]) {
		this.isSaving = true;

		let fileUploadResult: FileUploadResult = yield this.uploadFilesToS3(filesToUpload, activity.id);

		if (fileUploadResult.success) {
			const activityToSave = {
				...activity,
				files: filesToSave.map(f => ({
					fileName: f.fileName,
					fileSize: f.fileSize,
				})),
			};

			try {
				yield this.apiClient.cpdActivityClient.update(activityToSave.id, activityToSave);
			} catch (error) {
				throw error;
			} finally {
				this.isSaving = false;
			}
		} else {
			this.failedFileUploads = fileUploadResult.failedFiles;
		}

		this.isSaving = false;
	}

	private async uploadFilesToS3(filesToUpload: CpdDocumentNew[], activityId?: string): Promise<FileUploadResult> {
		const newFileNames = filesToUpload.map(f => f.filename);
		const presignedUrls: ActivityFilePresignedDto = await this.apiClient.cpdActivityFileClient.getPresignedUrlsForFiles(newFileNames, activityId);

		if (filesToUpload.length === 0) {
			return {
				success: true,
				failedFiles: [],
				activityId: presignedUrls.activityId,
			};
		}

		const filesFailedToUpload: string[] = [];

		await Promise.allSettled(
			filesToUpload.map(file =>
				uploadFileToS3(presignedUrls.filePresignedUrls[file.filename], file.file, percentage => {
					runInAction(() => {
						const newDocumentFile = this.newDocuments?.get(file.filename);
						if (newDocumentFile) {
							newDocumentFile.uploadedPercentage = percentage;
						}
					});
				}).catch(() => {
					filesFailedToUpload.push(file.filename);
					runInAction(() => {
						const newDocumentFile = this.newDocuments?.get(file.filename);
						if (newDocumentFile) {
							newDocumentFile.uploadedPercentage = null;
						}
					});
				}),
			),
		);

		return {
			success: filesFailedToUpload.length === 0,
			failedFiles: filesFailedToUpload,
			activityId: presignedUrls.activityId,
		};
	}

	private dedupeFilename(filename: string): string {
		const dedupe = (generatedName: string, currentIndex: number): string => {
			if (this.newDocuments.has(generatedName) || this.relatedDocuments.has(generatedName)) {
				const [name, ext] = splitFilename(filename);

				if (ext) {
					return dedupe(`${name}_${currentIndex}.${ext}`, currentIndex + 1);
				} else {
					return dedupe(`${name}_${currentIndex}`, currentIndex + 1);
				}
			} else {
				return generatedName;
			}
		};

		return dedupe(filename, 1);
	}

	@action
	addNewDocuments(files: FileList | File[]) {
		Array.from(files).forEach(file => {
			const name = this.dedupeFilename(file.name);
			const url = URL.createObjectURL(file);

			this.newDocuments.set(name, {
				id: name,
				filename: name,
				file: file,
				size: file.size,
				url: url,
				uploadedPercentage: 0,
			});
		});
	}

	@action
	removeDocument(id: string, isNewDocument: boolean) {
		if (isNewDocument) {
			this.newDocuments.delete(id);
		} else {
			this.relatedDocuments.delete(id);
			this.documentIdsToRemove.push(id);
		}
	}

	deleteActivity(cpdActivityId: string) {
		return this.apiClient.cpdActivityClient.delete(cpdActivityId);
	}

	getCreateActivityUrl(developmentGoalId: string | null | undefined): string {
		return developmentGoalId ? `${RouteConstants.cpdCreateActivity}/${developmentGoalId}` : RouteConstants.cpdCreateActivity;
	}
}
