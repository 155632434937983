import {computed, flow, makeObservable, observable} from "mobx";
import {IApiClient} from "../common/api.client";
import {DashboardCarouselsStore} from "./dashboard.carousels.store";
import {ActivityFromCourseDto, DlpDashboardContentDto} from "../common/webapicall";

export class DlpDashboardStore {
	private readonly apiClient: IApiClient;
	readonly carouselsStore: DashboardCarouselsStore;

	@observable content: DlpDashboardContentDto | null;

	constructor(apiClient: IApiClient) {
		makeObservable(this);

		this.apiClient = apiClient;
		this.carouselsStore = new DashboardCarouselsStore(apiClient);
	}

	@flow
	*init() {
		yield this.carouselsStore.init();

		this.content = yield this.apiClient.dlpDashboardClient.getContent();
	}

	@computed
	get hasAchievements(): boolean {
		return this.content?.achievementsEarned > 0;
	}

	@computed
	get hasInProgressCourses(): boolean {
		return this.content?.coursesInProgress > 0;
	}

	@computed
	get hasPromotionalEvent(): boolean {
		return this.content?.promotions?.some(p => p);
	}

	async getCourseForActivityCreation(courseId: string): Promise<ActivityFromCourseDto> {
		return await this.apiClient.dlpDashboardClient.getCourse(courseId);
	}
}
