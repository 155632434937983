import React, {useLayoutEffect, useState} from "react";
import {observer} from "mobx-react";
import {focusElementById} from "wcag-tools";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import style from "./top.header.module.scss";
import logoImg from "../assets/ciht-logo.png";
import {CihtOrgRouteConstants} from "../resources/ciht-org-route-constants";
import {useAppStore} from "./app.store.consumer";

export const Search = observer(() => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [searchTerm, setSearchTerm] = useState<string>("");

	const appStore = useAppStore();

	useLayoutEffect(() => {
		if (isOpen) {
			focusElementById("search-btn");
		}
	}, [isOpen]);

	function onSearch(e: React.FormEvent) {
		e.preventDefault();

		const searchUrl = CihtOrgRouteConstants.getSearchResults(appStore.configuration.cihtSiteUrl, searchTerm);
		window.open(searchUrl, "_blank");

		setIsOpen(false);
		setSearchTerm("");
	}

	return (
		<nav>
			<div>
				<div className={style.menuItem}>
					<div onClick={() => setIsOpen(prevState => !prevState)}>
						<span className={style.top}>
							<FontAwesomeIcon className={style.faIcon} transform="shrink-5" icon={["fas", "search"]} />
						</span>
						<span className={style.bottom}>
							Search
							{/*<FontAwesomeIcon transform="down-2 right-4" icon={["fas", "chevron-down"]} />*/}
						</span>
					</div>
					<div className={`${style.searchDropdown} ${isOpen ? style.open : ""}`}>
						<div className={style.floatingContainer}>
							<div className={style.searchAreaContainer}>
								<div>
									<div className={style.logo}>
										<img src={logoImg} alt="CIHT logo" />
									</div>
								</div>
								<form className={style.topSearchForm} onSubmit={onSearch}>
									<FontAwesomeIcon icon={["far", "search"]} />
									<input
										id="search-btn"
										type="text"
										placeholder="Type to search..."
										value={searchTerm}
										onChange={e => setSearchTerm(e.target.value)}
									/>
									<button type="submit">
										Search
									</button>
								</form>
							</div>
						</div>
						<FontAwesomeIcon className={style.icoClose} icon="times" onClick={() => setIsOpen(false)} />
					</div>
				</div>
			</div>
		</nav>
	);
});
