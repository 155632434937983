import {action, computed, flow, makeObservable, observable} from "mobx";
import {IApiClient} from "../common/api.client";
import {ApplicationType, CpdProgressDto, MemberDto, TokenResponse, UserDto, UserPreferenceDto} from "../common/webapicall";
import * as Cookies from "js-cookie";
import {IConfigurationProvider} from "../common/public.configuration";
import {LoadingState} from "../types/LoadingState";

export class UserStore {
	constructor(private apiClient: IApiClient, private configurationProvider: IConfigurationProvider) {
		makeObservable(this);
	}

	@observable isLoggedIn = false;
	@observable expiration: Date = null;
	@observable userId: string = null;
	@observable totaraId: number = null;
	@observable userName: string = null;
	@observable firstName: string = null;
	@observable lastName: string = null;
	@observable dateCreated: Date = null;
	@observable memberInfo: MemberDto = {};
	@observable isLoginVisible: boolean = false;
	@observable users: UserDto[] = [];
	@observable cpdProgress: CpdProgressDto = {};
	@observable userPreferences: UserPreferenceDto[] = [];
	@observable loadingState: LoadingState = "initial";

	@computed
	get hasMemberAccess(): boolean {
		return this.memberInfo?.hasDlpMemberAccess ?? false;
	}

	async logAuditTrail(screenName: string) {
		await this.apiClient.userClient.logAuditTrail({
			screenName: screenName,
			url: window.location.href,
		});
	}

	async loginByEmail(email: string, redirectUrl: string): Promise<boolean> {
		return await this.apiClient.userClient.loginByEmail(email, ApplicationType.ClientApp, redirectUrl);
	}

	@action
	async init() {
		const token = this.getAuthToken();
		const refreshToken = UserStore.getRefreshToken();

		if (token) {
			this.apiClient.setAuthToken(token, refreshToken);

			const userInfo = await this.apiClient.userClient.getInfo();
			this.isLoggedIn = true;
			this.userId = userInfo.userId;
			this.totaraId = userInfo.totaraId;
			this.userName = `${userInfo.firstName} ${userInfo.lastName}`;
			this.firstName = userInfo.firstName;
			this.lastName = userInfo.lastName;
			this.memberInfo = userInfo.memberInfo;
			this.dateCreated = userInfo.dateCreated ? new Date(userInfo.dateCreated) : null;
			this.userPreferences = userInfo.preferences;

			this.getCpdProgress();
		}

		if (this.configurationProvider.configuration.showUserDropdown && this.isLoggedIn) {
			this.users = await this.apiClient.lookupClient.getAllUsers();
		}

		this.loadingState = "completed";
	}

	@flow
	*getCpdProgress() {
		this.cpdProgress = yield this.apiClient.dlpDashboardClient.getCpdProgress();
	}

	async refreshToken(refreshToken: string) {
		let successful = true;
		let tokenResponse: TokenResponse;
		await this.apiClient.userClient
			.refreshToken({
				refreshToken: refreshToken,
			})
			.then(r => {
				successful = true;
				tokenResponse = r;
			})
			.catch(() => {
				successful = false;
			});

		if (successful) {
			UserStore.setAuthCookies(tokenResponse);
			this.apiClient.setAuthToken(tokenResponse.token, tokenResponse.refreshToken);
		}

		return successful;
	}

	getLoginUrl(): string {
		if (this.isLoginVisible) {
			return window.location.hash;
		} else {
			return this.getLoginRedirectUrl(window.location.hash);
		}
	}

	canChangeUser(): boolean {
		return this.configurationProvider.configuration.showUserDropdown;
	}

	async changeUser(userId: string) {
		if (this.canChangeUser()) {
			let tokenResponse = await this.apiClient.userClient.getToken(userId);
			this.updateTokens(tokenResponse);
		}
	}

	private updateTokens(tokenResponse: TokenResponse) {
		if (this.canChangeUser()) {
			UserStore.setAuthCookies(tokenResponse);
			window.location.href = "/";
		}
	}

	private getLoginRedirectUrl(redirectUrl?: string): string {
		let loginUrl = this.configurationProvider.configuration.loginUrl;
		if (!redirectUrl) {
			return loginUrl;
		}
		if (redirectUrl[0] !== "/") {
			redirectUrl = `/${redirectUrl}`;
		}
		return `${loginUrl}/${encodeURIComponent(redirectUrl)}`;
	}

	async logout() {
		this.apiClient.setAuthToken(null, null);
		window.location.href = this.configurationProvider.configuration.openIdLogoutUrl;
	}

	sendToOpenIdLogin(redirectUrl?: string): void {
		if (this.configurationProvider.configuration.isOpenIdLoginEnabled) {
			window.location.href = this.getOpenIdRedirectUrl(redirectUrl);
		}
	}

	private getOpenIdRedirectUrl(redirectUrl?: string): string {
		if (!redirectUrl) {
			return this.configurationProvider.configuration.openIdLoginUrl;
		}

		let queryParams = {};
		if (redirectUrl) {
			if (redirectUrl[0] !== "/") {
				redirectUrl = `/${redirectUrl}`;
			}
			queryParams = {...queryParams, redirectUrl: encodeURIComponent(redirectUrl)};
		}

		const queryString = new URLSearchParams(queryParams).toString();
		return `${this.configurationProvider.configuration.openIdLoginUrl}?${queryString}`;
	}

	getAuthToken(): string {
		return Cookies.get("authToken");
	}

	private static getRefreshToken() {
		return Cookies.get("refreshToken");
	}

	private static setAuthCookies(tokenResponse: TokenResponse) {
		Cookies.set("authToken", tokenResponse.token);
		Cookies.set("refreshToken", tokenResponse.refreshToken);
	}

	@computed
	get isUserCreatedBeforeGoLive() {
		const goLiveDate = new Date(this.configurationProvider.configuration.goLiveDate);
		return this.dateCreated !== null && this.dateCreated < goLiveDate;
	}
}
