import {action, flow, makeObservable, observable, runInAction} from "mobx";
import {IApiClient} from "../common/api.client";
import {IConfigurationProvider} from "../common/public.configuration";
import {CoursePageDetails, PurchaseDto} from "../common/webapicall";
import {LoadingState} from "../types/LoadingState";
import {isWebApiErrorResponse} from "../common/webapicall.extensions";

export class CourseDetailsStore {
	private readonly apiClient: IApiClient;

	@observable selectedCourse: CoursePageDetails | null;
	@observable loadingState: LoadingState;
	@observable purchaseLoadingState: LoadingState;

	constructor(apiClient: IApiClient, private configurationProvider: IConfigurationProvider) {
		makeObservable(this);
		this.apiClient = apiClient;

		this.selectedCourse = null;
		this.loadingState = "initial";
		this.purchaseLoadingState = "initial";
	}

	@flow
	*onPageLoad(courseId: string) {
		this.loadingState = "loading";

		try {
			const course: CoursePageDetails = yield this.apiClient.courseClient.getCourseDetails(courseId);

			if (!course) {
				this.loadingState = "not-found";
			}

			this.selectedCourse = course;

			this.loadingState = "completed";
		} catch (error) {
			if (isWebApiErrorResponse(error) && error.statusCode === 404) {
				this.loadingState = "not-found";
				return;
			} else {
				this.loadingState = "error";
			}
		}
	}

	@action
	onPageUnload() {
		this.selectedCourse = null;
		this.loadingState = "initial";
	}

	async purchase() {
		runInAction(() => {
			this.purchaseLoadingState = "loading";
		});

		const response: PurchaseDto = await this.apiClient.enrollmentClient.initiatePurchase(this.selectedCourse.id);
		runInAction(() => {
			this.selectedCourse.enrollmentStatus = {
				purchaseStatus: response.purchaseStatus,
				totaraEnrollmentStatus: response.enrollmentStatus,
				confirmationStatus: response.confirmationStatus,
			};

			this.purchaseLoadingState = "completed";
		});
	}

	getCmsPaymentApiUrl() {
		return this.configurationProvider.configuration.cmsPaymentApiUrl + `?courseId=${this.selectedCourse.crmProductId}`;
	}
}
