import React from "react";
import {AppStore} from "../stores/app.store";
import {ModalRenderer} from "./modal";
import {getAppStore} from "./app.store.consumer";

const OutDatedClientModal: React.FC<{reloadCallback: () => void}> = ({reloadCallback}) => {
	return (
		<ModalRenderer isVisible={true}>
			<div className="info-modal-content">
				<div className="info-modal-header  paint-lgrey">
					<h5 className="info-modal-title ink-dgrey">Outdated client</h5>
				</div>
				<div className="info-modal-border" />
				<div className="info-modal-body">
					<div className="info-modal-text">Your client application is outdated. Please refresh to continue your work!</div>
					<div className="vspace30" />
					<button onClick={reloadCallback} className="info-modal-button btn-base btn-primary  btn-fit-mobile  anim-btn-hover">
						Refresh
					</button>
				</div>
			</div>
		</ModalRenderer>
	);
};

@getAppStore()
export class OutdatedClient extends React.Component<{appStore?: AppStore}> {
	private refresh = () => {
		window.location.reload();
	};

	render() {
		const appStore = this.props.appStore;

		return appStore.outDatedClient ? <OutDatedClientModal reloadCallback={this.refresh} /> : null;
	}
}
