import React, {useEffect, useRef, useState} from "react";
import {ModalRenderer} from "./ModalRenderer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useKeyboardNavigation as keyboardNavigation, focusElementByIdLast} from "wcag-tools";
import style from "./ConfirmationModal.module.scss";

let lastModalId = 0;

export type ErrorModalProps = React.PropsWithChildren<{
	isVisible: boolean;
	close: () => void;
	tryAgainAction?: () => void | Promise<void>;
	["aria-labelledby"]?: string;
}>;

export function ErrorModal({close, children, isVisible, tryAgainAction, ...rest}: ErrorModalProps) {
	const shouldTryAgain = typeof tryAgainAction === "function";

	const confirmButtonIdRef = useRef(
		(() => {
			lastModalId += 1;
			return `em-tryagain-btn-${lastModalId}`;
		})(),
	);

	useEffect(() => {
		if (isVisible) {
			// noinspection JSIgnoredPromiseFromCall
			focusElementByIdLast(confirmButtonIdRef.current);
		}
	}, [isVisible]);

	const [isLoading, setIsLoading] = useState<boolean>(false);

	async function onTryAgain() {
		setIsLoading(true);

		try {
			await tryAgainAction();
		} catch (error) {
		} finally {
			setIsLoading(false);
		}
	}

	return (
		<ModalRenderer isVisible={isVisible} aria-labelledby={rest["aria-labelledby"]}>
			<div className="header">
				<FontAwesomeIcon
					className="tint-teal"
					icon={["fas", "times"]}
					onClick={close}
					{...keyboardNavigation<any>({clickable: true, role: "button", label: "Close dialog"})}
				/>
			</div>
			<div className="ciht-flex-center">
				<FontAwesomeIcon transform="grow-12 up-2" className="tint-red ciht-mr-1" icon="times-circle" /> 
				<h1 className="tint-red" >Error</h1>
			</div>
			<p>{children}</p>
			<div className="buttonRow">
				{shouldTryAgain && (
					<button disabled={isLoading} id={confirmButtonIdRef.current} {...keyboardNavigation({clickable: true})} onClick={onTryAgain}>
						{isLoading && <FontAwesomeIcon icon="spinner" spin />} Try Again
					</button>
				)}
				<button className={style.btn} {...keyboardNavigation({clickable: true})} onClick={close}>
					Close
				</button>
			</div>
		</ModalRenderer>
	);
}
