import React from "react";
import {getAppStore} from "../common/app.store.consumer";
import {AppStore} from "../stores/app.store";

@getAppStore()
export class UserDropdown extends React.Component<{appStore?: AppStore}> {
	private onUserSelected = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const userId = e.target.value;
		const userStore = this.props.appStore.userStore;

		return userStore.changeUser(userId);
	};

	render() {
		const isVisible = this.props.appStore.configuration.showUserDropdown && this.props.appStore.userStore.isLoggedIn;
		if (!isVisible) {
			return null;
		}
		const {users, userId} = this.props.appStore.userStore;

		return (
			<div className="tp_label">
				<span>Simulate login as:</span>
				<select className="tmp-element" onChange={this.onUserSelected} defaultValue={userId ? userId.toString() : null}>
					{users.map(user => (
						<option value={user.id} key={user.id}>
							{user.fullName}
						</option>
					))}
				</select>
			</div>
		);
	}
}
