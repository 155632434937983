import React from "react";
import {AppStore} from "../stores/app.store";
import {getAppStore} from "../common/app.store.consumer";
import {ValidatableForm, ValidatableInput, ValidationMessage} from "../common/validation";
import {RouteComponentProps, withRouter} from "react-router";
import * as Yup from "yup";

interface LoginInnerState {
	isSuccessful: boolean;
	isSuccessfulVisible: boolean;
	isUnsuccessfulVisible: boolean;
	model: {
		email?: string;
	};
	hideInitialState: boolean;
}

interface LoginProps {
	appStore?: AppStore;
	initialState: LoginState;
}

export enum LoginState {
	Default,
	SessionExpired,
	AccessLinkExpired,
	ForcedLogout,
	AccessLinkNotFound,
	AccessDenied,
}

type LoginPropsWithRouting = LoginProps & RouteComponentProps<{redirectUrl?: string}>;

const emailModel = Yup.object({
	email: Yup.string()
		.required("Email field is required.")
		.email("Please enter a valid email address."),
});

@getAppStore()
export default class LoginWithoutRouter extends React.Component<LoginPropsWithRouting, LoginInnerState> {
	constructor(props: LoginPropsWithRouting) {
		super(props);
		this.state = {
			isSuccessful: false,
			isSuccessfulVisible: false,
			isUnsuccessfulVisible: false,
			model: {
				email: "",
			},
			hideInitialState: false,
		};
	}

	componentDidMount() {
		this.props.appStore.userStore.isLoginVisible = true;
	}

	componentWillUnmount() {
		this.props.appStore.userStore.isLoginVisible = false;
	}

	private onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		this.setState({
			isSuccessfulVisible: false,
			isUnsuccessfulVisible: false,
		});
		let userStore = this.props.appStore.userStore;
		let res = await userStore.loginByEmail(this.state.model.email, this.getRedirectUrl());
		this.setState({
			isSuccessful: res,
			isSuccessfulVisible: true,
			isUnsuccessfulVisible: false,
		});
		window.scrollTo(0, 0);
	};

	private onEmailChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({
			model: {
				email: e.target.value,
			},
		});
	};

	private onEmailFocused = () => {
		this.setState({
			isSuccessfulVisible: false,
			isUnsuccessfulVisible: false,
			hideInitialState: true,
		});
	};

	private getRedirectUrl(): string {
		if (this.props.match.params.redirectUrl) {
			return decodeURIComponent(this.props.match.params.redirectUrl);
		}
		return "/#/";
	}

	private onClickLoginWithSSO = () => {
		this.props.appStore.userStore.sendToOpenIdLogin(this.getRedirectUrl());
	};

	render() {
		if (!this.props.appStore.configuration.isLoginPageEnabled) {
			this.props.appStore.userStore.sendToOpenIdLogin(this.getRedirectUrl());
			return null;
		}

		let loginSuccessfulPart = this.state.isSuccessful && this.state.isSuccessfulVisible && <div>Your login was successful, check your email inbox.</div>;
		return (
			<div className="loginPage">
				<ValidatableForm
					onSubmit={(e: React.FormEvent<HTMLFormElement>) => this.onSubmit(e)}
					model={this.state.model}
					validateOnBlur={false}
					schema={emailModel}
				>
					{validationResult => (
						<>
							<div>
								<div>
									<ValidatableInput
										model={this.state.model}
										fieldName="email"
										onChanged={(e: React.ChangeEvent<HTMLInputElement>) => this.onEmailChanged(e)}
									>
										<input type="text" id="email" name="email" placeholder="Enter your email" onFocus={() => this.onEmailFocused()} />
									</ValidatableInput>
								</div>
								<div>
									<button>Send</button>
									{this.props.appStore.configuration.isOpenIdLoginEnabled && (
										<button className="cih-btn-sso linkALike" type="button" onClick={this.onClickLoginWithSSO}>
											Click here to Login with SSO
										</button>
									)}
								</div>
							</div>
							<div>
								<ValidationMessage fieldName="email" validationResult={validationResult} />
								{loginSuccessfulPart}
							</div>
						</>
					)}
				</ValidatableForm>
			</div>
		);
	}
}

export const Login = withRouter(LoginWithoutRouter);
