import {ICourseClient, CourseTiles, GetCourseTilesResponse} from "../common/webapicall";
import {action, flow, makeObservable, observable} from "mobx";
import {LoadingState} from "../types/LoadingState";

export class CourseCarouselStore {
	@observable loadingState: LoadingState;
	@observable courses: CourseTiles[];

	constructor(private apiClient: ICourseClient) {
		makeObservable(this);

		this.courses = [];
		this.loadingState = "initial";
	}

	@flow
	*init() {
		this.loadingState = "loading";

		const courseTilesResponse: GetCourseTilesResponse = yield this.apiClient.getCourseTiles();
		this.courses = courseTilesResponse.courseTiles.sort((a, b) => a.orderValue - b.orderValue);

		this.loadingState = "completed";
	}

	@action
	unload() {
		this.loadingState = "initial";
		this.courses = [];
	}
}
