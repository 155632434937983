import React from "react";
import ReactDOM from "react-dom";
import {useTrapFocus as trapFocus} from "wcag-tools";

class Backdrop extends React.Component<{
	show: boolean;
	modalClicked: (e?: React.MouseEvent) => void;
	classes: string;
}> {
	render() {
		return this.props.show && <div className={this.props.classes} onClick={this.props.modalClicked} />;
	}
}

class Modal extends React.Component<{
	show: boolean;
	modalClicked: (e?: React.MouseEvent) => void;
	modalClassName: string;
	modalWrapperClassName: string;
	backdropClassName: string;
	wcagOverwriteTrapFocusSelector?: string;
	noBackdrop?: boolean;
	["aria-label"]?: string;
	["aria-labelledby"]?: string;
}> {
	render() {
		return (
			<React.Fragment>
				{!this.props.noBackdrop && <Backdrop show={this.props.show} classes={this.props.backdropClassName} modalClicked={this.props.modalClicked} />}
				<div className={this.props.modalWrapperClassName}>
					<div
						className={this.props.modalClassName}
						role="dialog"
						aria-modal
						aria-hidden={!this.props.show}
						aria-label={this.props["aria-label"]}
						aria-labelledby={this.props["aria-labelledby"]}
						style={{
							display: this.props.show ? "flex" : "none",
						}}
						{...trapFocus({overrideFocusableElementSelector: this.props.wcagOverwriteTrapFocusSelector})}
					>
						{this.props.children}
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export type ModalRendererProps = React.PropsWithChildren<{
	/**
	 * If true the modal renders to the dom even when hidden.
	 * @default false
	 */
	renderWhenHidden?: boolean;
	/**
	 * If true, no backdrop component will be rendered under the modal.
	 * @default false
	 */
	noBackdrop?: boolean;
	/**
	 * If true, the modal will render in the resulting DOM where it is placed in the component hierarchy instead of directly under document.body.
	 * @default false
	 */
	renderInline?: boolean;
	isVisible: boolean;
	toggleModal?: () => void;

	/**
	 * External className property that is applied to the modal <div />.
	 * It either overwrites or appends to the default className (controlled by overwriteModalClassName).
	 */
	modalClassName?: string;

	/**
	 * If true the modalClassName prop overwrites the default modal className instead of appending to it.
	 * @default false
	 */
	overwriteModalClassName?: boolean;

	/**
	 * External className property that is applied to the modal's wrapper <div />.
	 * It either overwrites or appends to the default className (controlled by overwriteModalWrapperClassName).
	 */
	modalWrapperClassName?: string;

	/**
	 * If true the modalWrapperClassName prop overwrites the default modal className instead of appending to it.
	 * @default false
	 */
	overwriteModalWrapperClassName?: boolean;

	/**
	 * External className property that is applied to the modal's backdrop <div />.
	 * It either overwrites or appends to the default className (controlled by overwriteBackdropClassName).
	 */
	backdropClassName?: string;

	/**
	 * If true the backdropClassName prop overwrites the default backdrop className instead of appending to it.
	 * @default false
	 */
	overwriteBackdropClassName?: boolean;

	/**
	 * Overwrites the default selector used by the useTrapFocus helper in the wcag-tools library.
	 */
	wcagOverwriteTrapFocusSelector?: string;
	["aria-label"]?: string;
	["aria-labelledby"]?: string;
}>;

export const ModalRenderer = ({
	renderWhenHidden = false,
	renderInline = false,
	noBackdrop = false,
	toggleModal,
	isVisible,
	children,
	modalClassName = "",
	overwriteModalClassName = false,
	backdropClassName = "",
	overwriteBackdropClassName = false,
	modalWrapperClassName = "",
	overwriteModalWrapperClassName = false,
	wcagOverwriteTrapFocusSelector,
	...rest
}: ModalRendererProps) => {
	const backdropClassNameResult = overwriteBackdropClassName ? backdropClassName : `${backdropClassName} modalPopupBg`;
	const modalClassNameResult = overwriteModalClassName ? modalClassName : `${modalClassName} modalPopup`;
	const modalWrapperClassNameResult = overwriteModalWrapperClassName ? modalWrapperClassName : `${modalWrapperClassName} modalWrapper`;

	const modal = (
		<Modal
			noBackdrop={noBackdrop}
			show={isVisible}
			modalClicked={toggleModal}
			modalClassName={modalClassNameResult}
			modalWrapperClassName={modalWrapperClassNameResult}
			backdropClassName={backdropClassNameResult}
			aria-labelledby={rest["aria-labelledby"]}
			aria-label={rest["aria-label"]}
			wcagOverwriteTrapFocusSelector={wcagOverwriteTrapFocusSelector}
		>
			{children}
		</Modal>
	);

	if (renderInline) {
		return (renderWhenHidden || isVisible) && modal;
	} else {
		return (renderWhenHidden || isVisible) && ReactDOM.createPortal(modal, document.body);
	}
};
