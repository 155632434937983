import React from "react";
import {Switch, Route} from "react-router-dom";
import {Redirect} from "react-router";
import {NotFound} from "./not.found";
import {LoginState, Login} from "../login/login";
import {renderProtectedElement} from "./protected.page";
import {RouteConstants} from "../resources/route-constants";
import {BecomeMember} from "../cpd/BecomeMember";

const Dlp = React.lazy(() => import("../dlp"));
const Cpd = React.lazy(() => import("../cpd"));

const getLoginComponent = (state: LoginState) => {
	return () => <Login initialState={state} />;
};

/** Defines the routes of the application. */
export const Routes = () => {
	return (
		<>
			<Switch>
				<Redirect from="/" to="/dlp/dashboard" exact />

				<Route path={RouteConstants.dlp} component={renderProtectedElement(Dlp)} />
				<Route path={RouteConstants.cpd} component={renderProtectedElement(Cpd)} />

				<Route path={RouteConstants.notFound} component={NotFound} />

				<Route path={RouteConstants.becomeMember} component={BecomeMember} />
				<Route path={RouteConstants.loginWithRedirectUrl} component={getLoginComponent(LoginState.Default)} />
				<Route path={RouteConstants.sessionExpired} component={getLoginComponent(LoginState.SessionExpired)} />
				<Route path={RouteConstants.accessLinkExpired} component={getLoginComponent(LoginState.AccessLinkExpired)} />
				<Route path={RouteConstants.forcedLogout} component={getLoginComponent(LoginState.ForcedLogout)} />
				<Route path={RouteConstants.accessLinkNotFound} component={getLoginComponent(LoginState.AccessLinkNotFound)} />
				<Route path={RouteConstants.accessDenied} component={getLoginComponent(LoginState.AccessDenied)} />

				<Redirect to={RouteConstants.notFound} />
			</Switch>
		</>
	);
};
