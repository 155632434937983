import {action, flow, makeObservable, observable} from "mobx";
import {LoadingState} from "../types/LoadingState";
import {SaveState} from "../types/SaveState";
import {IApiClient} from "../common/api.client";
import {SwotDto} from "../common/webapicall";

export class SwotStore {
	@observable strength: string;
	@observable weakness: string;
	@observable opportunity: string;
	@observable threats: string;
	@observable gaps: string;
	@observable focus: string;

	@observable initialSwotValues: SwotDto;

	@observable loadingState: LoadingState;
	@observable saveState: SaveState;

	constructor(private apiClient: IApiClient) {
		makeObservable(this);

		this.strength = "";
		this.weakness = "";
		this.opportunity = "";
		this.threats = "";
		this.gaps = "";
		this.focus = "";

		this.initialSwotValues = {
			strength: "",
			weakness: "",
			opportunity: "",
			threats: "",
			gaps: "",
			focus: "",
		};

		this.loadingState = "initial";
		this.saveState = "saved";
	}

	@flow
	*init() {
		this.loadingState = "loading";

		try {
			const swot: SwotDto = yield this.apiClient.swotClient.getByUserId();

			this.initialSwotValues = swot;

			this.strength = swot?.strength ?? "";
			this.weakness = swot?.weakness ?? "";
			this.opportunity = swot?.opportunity ?? "";
			this.threats = swot?.threats ?? "";
			this.gaps = swot?.gaps ?? "";
			this.focus = swot?.focus ?? "";

			this.loadingState = "completed";
			this.saveState = "saved";
		} catch {
			this.loadingState = "error";
		}
	}

	@action
	unload() {
		this.loadingState = "initial";
		this.saveState = "dirty";

		this.strength = "";
		this.weakness = "";
		this.opportunity = "";
		this.threats = "";
		this.gaps = "";
		this.focus = "";
	}

	@flow
	*save() {
		if (this.saveState !== "dirty") {
			return;
		}

		const model: SwotDto = {
			strength: this.strength,
			weakness: this.weakness,
			opportunity: this.opportunity,
			threats: this.threats,
			gaps: this.gaps,
			focus: this.focus,
		};

		try {
			yield this.apiClient.swotClient.upsert(model);

			this.initialSwotValues = model;

			if (this.saveState !== "dirty") {
				this.saveState = "saved";
			}
		} catch {
			// todo: error handling?
		}
	}

	@action
	cancel() {
		this.strength = this.initialSwotValues.strength ?? "";
		this.weakness = this.initialSwotValues.weakness ?? "";
		this.opportunity = this.initialSwotValues.opportunity ?? "";
		this.threats = this.initialSwotValues.threats ?? "";
		this.gaps = this.initialSwotValues.gaps ?? "";
		this.focus = this.initialSwotValues.focus ?? "";

		this.saveState = "saved";
	}

	@action
	setStrength(value: string) {
		this.strength = value;
		this.saveState = "dirty";
	}

	@action
	setWeakness(value: string) {
		this.weakness = value;
		this.saveState = "dirty";
	}

	@action
	setOpportunity(value: string) {
		this.opportunity = value;
		this.saveState = "dirty";
	}

	@action
	setThreats(value: string) {
		this.threats = value;
		this.saveState = "dirty";
	}

	@action
	setGaps(value: string) {
		this.gaps = value;
		this.saveState = "dirty";
	}

	@action
	setFocus(value: string) {
		this.focus = value;
		this.saveState = "dirty";
	}
}
