import {action, computed, flow, flowResult, makeObservable, observable} from "mobx";
import {ICourseClient, ILookupClient, LookupItemDto, RegionDto, TopicDto} from "../common/webapicall";
import {LoadingState} from "../types/LoadingState";
import {isWebApiErrorResponse} from "../common/webapicall.extensions";

export class Lookup<TData extends LookupItemDto = LookupItemDto> {
	@observable loadingState: LoadingState;
	@observable data: TData[];

	private readonly loadDataFn: () => Promise<TData[]> | TData[];

	constructor(loadData: () => Promise<TData[]> | TData[]) {
		makeObservable(this);

		this.loadingState = "initial";
		this.loadDataFn = loadData;
		this.data = [];
	}

	@flow
	*loadData() {
		if (this.loadingState === "loading" || this.loadingState === "completed") {
			return;
		}

		this.loadingState = "loading";

		try {
			this.data = yield Promise.resolve(this.loadDataFn());

			this.loadingState = "completed";
		} catch (error) {
			if (isWebApiErrorResponse(error) && error.statusCode === 404) {
				this.loadingState = "not-found";
			} else {
				this.loadingState = "error";
			}

			throw error;
		}
	}

	@action
	unload() {
		this.data = [];
		this.loadingState = "initial";
	}
}

export class LookupStore {
	private readonly activityTypesLookup: Lookup;
	private readonly topicsLookup: Lookup;
	private readonly regionsLookup: Lookup<RegionDto>;
	private readonly contentTypesLookup: Lookup;
	private readonly eventTypesLookup: Lookup;
	private readonly courseTopicsLookup: Lookup<TopicDto>;

	constructor(private lookupClient: ILookupClient, private courseClient: ICourseClient) {
		makeObservable(this);

		this.activityTypesLookup = new Lookup(() => this.lookupClient.getAllActivityTypes());
		this.topicsLookup = new Lookup(() => this.lookupClient.getAllTopics());
		this.regionsLookup = new Lookup<RegionDto>(() => this.lookupClient.getAllRegions());
		this.contentTypesLookup = new Lookup(() => this.lookupClient.getAllContentTypes());
		this.eventTypesLookup = new Lookup(() => this.lookupClient.getAllEventTypes());
		this.courseTopicsLookup = new Lookup<TopicDto>(() => this.courseClient.getCourseTopics());
	}

	@computed
	get activityTypes() {
		return this.activityTypesLookup.data;
	}

	@computed
	get topics() {
		return this.topicsLookup.data;
	}
	
	@computed
	get courseTopics() {
		return this.courseTopicsLookup.data;
	}

	@computed
	get totalCourseCount() {
		return this.courseTopics.reduce((a, b) => {
			return a + b.numberOfCourses;
		}, 0);
	}

	@computed
	get topicsLoadingState() {
		return this.topicsLookup.loadingState;
	}

	@computed
	get regions() {
		return this.regionsLookup.data;
	}

	@computed
	get contentTypes() {
		return this.contentTypesLookup.data;
	}

	@computed
	get eventTypes() {
		return this.eventTypesLookup.data;
	}

	loadActivityTypes(): Promise<void> {
		return flowResult(this.activityTypesLookup.loadData());
	}

	loadTopics(): Promise<void> {
		return flowResult(this.topicsLookup.loadData());
	}

	loadRegions(): Promise<void> {
		return flowResult(this.regionsLookup.loadData());
	}

	loadContentTypes(): Promise<void> {
		return flowResult(this.contentTypesLookup.loadData());
	}

	loadEventTypes(): Promise<void> {
		return flowResult(this.eventTypesLookup.loadData());
	}

	loadCourseTopics(): Promise<void> {
		return flowResult(this.courseTopicsLookup.loadData());
	}

	unload() {
		this.activityTypesLookup.unload();
		this.topicsLookup.unload();
		this.regionsLookup.unload();
		this.contentTypesLookup.unload();
		this.eventTypesLookup.unload();
		this.courseTopicsLookup.unload();
	}
}
