import React, {HTMLAttributes} from "react";
import {ObjectShape} from "yup/lib/object";
import * as Yup from "yup";
import {Observer} from "mobx-react";

export interface ValidatableFormProps<TSchema extends object> extends React.DetailedHTMLProps<HTMLAttributes<HTMLFormElement>, HTMLFormElement> {
	children: (isValid: ValidationResult) => any;
	schema: Yup.ObjectSchema<ObjectShape>;
	model: TSchema;
	onSubmit?: (e?: React.FormEvent<HTMLFormElement>) => void | Promise<void>;
	onValidationErrorOnSubmit?: (validationResult?: ValidationResult) => void | Promise<void>;
	validateOnBlur?: boolean;
}

export interface ValidationResult {
	isValid: boolean;
	errorMessages?: {[key: string]: string};
	resetValidation: () => void;
}

/** Base component for validatable forms. It renders its children to the page, and adds some event handlers to them. */
export class ValidatableForm<TSchema extends object> extends React.Component<ValidatableFormProps<TSchema>, ValidationResult> {
	private focusedElements: string[] = [];

	constructor(props: Readonly<ValidatableFormProps<TSchema>>) {
		super(props);
		this.state = this.validate();
	}

	private validate(): ValidationResult {
		try {
			this.props.schema.validateSync(this.props.model, {
				abortEarly: false,
			});
			return {
				isValid: true,
				errorMessages: {},
				resetValidation: this.resetValidation,
			};
		} catch (e) {
			const error = e as Yup.ValidationError;
			const res: ValidationResult = {
				isValid: false,
				errorMessages: {},
				resetValidation: this.resetValidation,
			};

			const elements = this.state ? this.focusedElements : [];

			error.inner.filter(i => elements.some(fe => fe === i.path)).forEach(i => (res.errorMessages[i.path] = i.message));

			return res;
		}
	}

	private resetValidation = () => {
		this.focusedElements.length = 0;

		this.setState({
			errorMessages: {},
			resetValidation: this.resetValidation,
		});
	};

	private onFocus = (e: React.FocusEvent<HTMLElement>) => {
		const focusedElements = this.focusedElements;
		const fieldName = e.target.dataset["fieldName"];

		if (fieldName && !focusedElements.some(fe => fe === fieldName)) {
			focusedElements.push(fieldName);
		}
	};

	private onBlur = () => {
		if (this.shouldValidateOnBlur()) {
			this.setState(this.validate());
		}
	};

	private onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		const elements = Object.keys(this.props.model);
		elements.forEach(e => {
			if (!this.focusedElements.some(fe => fe === e)) {
				this.focusedElements.push(e);
			}
		});

		const validationResult = this.validate();

		if (validationResult.isValid) {
			if (this.props.onSubmit) {
				this.setState(validationResult);
				this.props.onSubmit(e);
			}
		} else {
			if (this.props.onValidationErrorOnSubmit) {
				this.props.onValidationErrorOnSubmit(validationResult);
			}

			this.setState(validationResult);
		}
	};

	private onKeyup = () => {
		if (this.shouldValidateOnBlur()) {
			const validationResult = this.validate();
			// not updating the validation messages intentionally, as they should be updated upon focus lost or submission.
			// the form can be submitted this way without removing focus from any other element
			this.setState(s => ({
				isValid: validationResult.isValid,
				errorMessages: s.errorMessages,
				resetValidation: this.resetValidation,
			}));
		}
	};

	private shouldValidateOnBlur() {
		return this.props.validateOnBlur ?? true;
	}

	componentDidUpdate() {
		if (this.shouldValidateOnBlur()) {
			const validationResult = this.validate();
			const currentValidationResult = this.state;

			if (JSON.stringify(validationResult) !== JSON.stringify(currentValidationResult)) {
				this.setState(validationResult);
			}
		}
	}

	render() {
		const {children, schema, model, onSubmit, onValidationErrorOnSubmit, validateOnBlur, ...restProps} = this.props;

		if (onSubmit) {
			return (
				<form onSubmit={this.onSubmit} onBlur={this.onBlur} onFocus={this.onFocus} onKeyUp={this.onKeyup} {...restProps}>
					<Observer render={() => children(this.state)} />
				</form>
			);
		} else {
			return children(this.state);
		}
	}
}

export interface ValidationMessageProps {
	validationResult: ValidationResult;
	fieldName: string;
	className?: string;
	style?: React.CSSProperties;
}

/**
 * Validation message component.
 * @param props Takes vaidation results from props, and shows the error message, if there's any.
 */
export const ValidationMessage = (props: ValidationMessageProps) => {
	const errorMessage = props.validationResult.errorMessages[props.fieldName];
	return (
		<span className={props.className} style={props.style} role="alert">
			{errorMessage}
		</span>
	);
};

export interface ValidationMessageWithIconProps {
	validationResult: ValidationResult;
	fieldName: string;
	classNames?: string;
	iconClassNames?: string;
}

/**
 * Validation message with icon component.
 * @param props Takes vaidation results from props, and shows the error message, if there's any.
 */
export const ValidationMessageWithIcon = (props: ValidationMessageWithIconProps) => {
	const errorMessage = props.validationResult.errorMessages[props.fieldName];
	return errorMessage ? (
		<div className={props.classNames}>
			<i className={props.iconClassNames} />
			<span>{errorMessage}</span>
		</div>
	) : null;
};

export interface ValidatableInputProps<TModel> {
	model: TModel;
	fieldName: keyof TModel;
	onChanged: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void | Promise<void>;
	children: any;
	value?: any;
}

/**
 * Validatable input component. Renders its children, and puts some additional properties on them.
 * @param props Input props for handling events of the input field.
 */
export const ValidatableInput = <TModel extends any>(props: ValidatableInputProps<TModel>) => {
	const val = props.value !== undefined ? props.value : props.model[props.fieldName];
	const mappedProps: any = {
		onChange: props.onChanged,
		"data-field-name": props.fieldName,
	};

	if (props.children.type === "input" && props.children.props.type === "radio") {
		mappedProps.checked = val === props.children.props.value;
	} else {
		mappedProps.value = val || "";
	}

	return React.cloneElement(props.children, mappedProps);
};
