import {action, flow, makeObservable, observable} from "mobx";
import {LoadingState} from "../types/LoadingState";
import {DevelopmentGoalDto, IDevelopmentGoalClient, LookupItemDto} from "../common/webapicall";
import {LookupStore} from "./lookup.store";
import {isWebApiErrorResponse} from "../common/webapicall.extensions";

export class CpdGoalStore {
	@observable savedGoal: DevelopmentGoalDto | null;
	@observable loadingState: LoadingState;
	@observable isSaving: boolean;
	@observable isDeleting: boolean;
	@observable lookupData: LookupItemDto[];
	@observable originalGoal: DevelopmentGoalDto | null;

	constructor(private apiClient: IDevelopmentGoalClient, private lookupStore: LookupStore) {
		makeObservable(this);

		this.savedGoal = null;
		this.loadingState = "initial";
		this.isSaving = false;
		this.isDeleting = false;
		this.lookupData = [];
	}

	@flow
	*init(goalId: string | null | undefined) {
		this.loadingState = "loading";

		try {
			if (goalId) {
				this.savedGoal = yield this.apiClient.get(goalId);
				this.savedGoal.targetDate = new Date(this.savedGoal.targetDate);
				this.originalGoal = this.savedGoal;
			} else {
				this.savedGoal = null;
				this.originalGoal = null;
			}

			yield this.lookupStore.loadTopics();
			yield this.lookupStore.loadActivityTypes();

			this.lookupData = yield this.apiClient.getLookupData();
		} catch (error) {
			if (isWebApiErrorResponse(error) && error.statusCode === 404) {
				this.loadingState = "not-found";
				return;
			} else {
				this.loadingState = "error";
				throw error;
			}
		}

		this.loadingState = "completed";
	}

	@flow
	*saveGoal(goal: DevelopmentGoalDto) {
		this.isSaving = true;

		try {
			if (goal.id) {
				yield this.apiClient.update(goal);
			} else {
				this.savedGoal = goal;
				this.savedGoal.id = yield this.apiClient.create(goal);
			}

			this.originalGoal = goal;
		} catch (error) {
			throw error;
		} finally {
			this.isSaving = false;
		}
	}

	@flow
	*deleteGoal(goalId: string, deleteActivities: boolean) {
		this.isDeleting = true;

		try {
			yield this.apiClient.delete(goalId, deleteActivities);
		} catch (error) {
			throw error;
		} finally {
			this.isDeleting = false;
		}
	}

	@action
	unload() {
		this.savedGoal = null;
		this.lookupData = [];
		this.loadingState = "initial";
	}

	@flow
	*completeGoal(goalId: string) {
		this.isSaving = true;

		try {
			yield this.apiClient.complete(goalId);
			this.savedGoal.dateCompleted = new Date();
		} catch (error) {
			throw error;
		} finally {
			this.isSaving = false;
		}
	}
}
