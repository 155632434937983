import React from "react";
import {RouteComponentProps, StaticContext, withRouter} from "react-router";
import {AppStore} from "../stores/app.store";
import {getAppStore} from "./app.store.consumer";
import {MainBlank} from "./blank";
import style from "./protected.page.module.scss";

export interface ProtectedPageProps extends RouteComponentProps<any, StaticContext, any> {
	appStore?: AppStore;
	component: React.ExoticComponent | React.ComponentType;
	isAdminPage: boolean;
}

export interface ProtectedPageState {
	initialized: boolean;
	pathname: string;
}

@getAppStore()
class ProtectedPageWithoutRouter extends React.Component<ProtectedPageProps, ProtectedPageState> {
	state: ProtectedPageState = {
		initialized: false,
		pathname: null,
	};

	private _prevComponent: JSX.Element = null;

	async componentDidMount() {
		await this.handleUrlChange();
	}

	private async handleUrlChange() {
		this.setState({
			initialized: true,
			pathname: this.props.location.pathname,
		});
		window.scrollTo(0, 0);
	}

	static getDerivedStateFromProps(nextProps: Readonly<ProtectedPageProps>, prevState: Readonly<ProtectedPageState>): ProtectedPageState {
		if (nextProps.location.pathname !== prevState.pathname) {
			return {
				initialized: false,
				pathname: nextProps.location.pathname,
			};
		} else {
			return null;
		}
	}

	async componentDidUpdate(prevProps: ProtectedPageProps) {
		if (prevProps.location.pathname !== this.props.location.pathname) {
			await this.handleUrlChange();
		}
	}

	render() {
		let children: JSX.Element;
		const Component = this.props.component;

		if (this.state.initialized) {
			children = <Component />;
			this._prevComponent = children;
		} else {
			children = this._prevComponent ? this._prevComponent : <MainBlank />;
		}

		return (
			<div className={style.protectedPageWrapper}>
				<div className={style.pageLayout}>{children}</div>
			</div>
		);
	}
}

const ProtectedPage = withRouter(ProtectedPageWithoutRouter);

/**
 * Wraps protected element with header and footer.
 * @param Component The component that is the main component of the page.
 */
export const renderProtectedElement = (Component: React.ExoticComponent | React.ComponentType, isAdminPage?: boolean) => {
	return () => {
		return <ProtectedPage component={Component} isAdminPage={isAdminPage ?? false} />;
	};
};
