import {ICourseClient, CourseLoadOptions} from "../common/webapicall";
import {action, makeObservable, observable} from "mobx";
import {LoadingState} from "../types/LoadingState";
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import {LoadOptions} from "devextreme/data/load_options";

export class CourseAutocompleteStore {
	@observable loadingState: LoadingState;
	@observable dataSource: DataSource | null = null;
	@observable searchValue: string = "";

	constructor(private apiClient: ICourseClient) {
		makeObservable(this);

		this.dataSource = null;
		this.loadingState = "initial";
	}

	@action
	init() {
		this.loadingState = "loading";

		this.dataSource = new DataSource(
			new CustomStore({
				load: async loadOptions => {
					return this.apiClient.getCarouselAutocompleteData(this.getCourseLoadOptions(loadOptions));
				},
			}),
		);

		this.loadingState = "completed";
	}

	private getCourseLoadOptions(loadOptions: LoadOptions): CourseLoadOptions {
		const courseLoadOptions = loadOptions as CourseLoadOptions;
		courseLoadOptions.searchValue = this.searchValue;

		return loadOptions;
	}

	@action
	setSearchValue(value: string) {
		this.searchValue = value;
	}

	@action
	unload() {
		this.dataSource = null;
		this.searchValue = "";
	}
}
