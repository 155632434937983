import style from "./top.footer.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useAppStore} from "../common/app.store.consumer";
import {CihtOrgRouteConstants} from "../resources/ciht-org-route-constants";

export function TopFooter() {
	const {configuration} = useAppStore();

	const cihtSiteUrl = configuration.cihtSiteUrl;

	const termsAndConditionsUrl = CihtOrgRouteConstants.getTermsAndConditions(cihtSiteUrl);
	const privacyStatementUrl = CihtOrgRouteConstants.getPrivacyStatement(cihtSiteUrl);
	const vacanciesAtCihtUrl = CihtOrgRouteConstants.getVacanciesAtCiht(cihtSiteUrl);
	const disclaimerUrl = CihtOrgRouteConstants.getDisclaimer(cihtSiteUrl);
	const contactUsUrl = CihtOrgRouteConstants.getContactUs(cihtSiteUrl);

	return (
		<footer className={style["top-footer"]}>
			<div className={style["follow-us"]}>Follow Us:</div>
			<div className={style["social-links"]}>
				<a href="https://twitter.com/CIHTUK">
					<FontAwesomeIcon icon={["fab", "twitter"]} />
				</a>
				<a href="https://www.youtube.com/channel/UC6N4M8NTRYRFiniwnE-efUg">
					<FontAwesomeIcon icon={["fab", "youtube"]} />
				</a>
				<a href="https://www.linkedin.com/company/chartered-institution-of-highways-&-transportation">
					<FontAwesomeIcon icon={["fab", "linkedin"]} />
				</a>
				<a href={cihtSiteUrl}>
					<FontAwesomeIcon icon={["fas", "podcast"]} />
				</a>
			</div>
			<div className={style.info}>CIHT is a charity registered in England (1136896), Scotland (SC040873) and the Republic of Ireland (20103989).</div>
			<div className={style.links}>
				<a href={termsAndConditionsUrl}>Terms and conditions</a>
				<a href={privacyStatementUrl}>Privacy statement</a>
				<a href={vacanciesAtCihtUrl}>Vacancies at CIHT</a>
				<a href={disclaimerUrl}>Disclaimer</a>
				<a href={contactUsUrl}>Contact Us</a>
			</div>
		</footer>
	);
}
