import {action, flow, makeObservable, observable} from "mobx";
import {CpdActivityDto, CpdYearDto} from "../common/webapicall";
import {LoadingState} from "../types/LoadingState";
import {IApiClient} from "../common/api.client";

export class CpdDashboardStore {
	@observable loadingState: LoadingState;
	@observable years: CpdYearDto[];
	@observable activities: CpdActivityDto[];

	constructor(private apiClient: IApiClient) {
		makeObservable(this);

		this.loadingState = "initial";
		this.years = [];
		this.activities = [];
	}

	@flow
	*init() {
		this.loadingState = "loading";
		try {
			const years: CpdYearDto[] = yield this.apiClient.cpdDashboardClient.getYears();
			years.sort((a, b) => (a.year > b.year ? -1 : 1));

			this.years = years;
		} catch {
			this.loadingState = "error";
		}
		this.loadingState = "completed";
	}

	@flow
	*getYearlyData(year: number) {
		this.loadingState = "loading";

		try {
			this.activities = yield this.apiClient.cpdDashboardClient.getActivities(year);
		} catch {
			this.loadingState = "error";
		}

		this.loadingState = "completed";
	}

	@action
	unload() {
		this.years = [];
		this.activities = [];
		this.loadingState = "initial";
	}

	@flow
	*deleteActivity(activityId: string) {
		this.loadingState = "loading";

		yield this.apiClient.cpdActivityClient.delete(activityId);

		this.loadingState = "completed";
	}

	async getActivitiesExcel(year: number) {
		return await this.apiClient.cpdDashboardClient.getActivitiesExcel(year);
	}
}
