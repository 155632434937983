import {library} from "@fortawesome/fontawesome-svg-core";
import {
	faAd,
	faBars,
	faCar,
	faCarCrash,
	faChartPie,
	faCheck,
	faCheckCircle,
	faChevronDown,
	faChevronUp,
	faCog,
	faCross,
	faDolly,
	faExclamationCircle,
	faExternalLink,
	faFileExcel,
	faInfoCircle,
	faMedal,
	faMinus,
	faPodcast,
	faArrowToRight,
	faSearch,
	faShield,
	faSpinner,
	faTimes,
	faTrash,
	faUser,
	faTimesCircle,
	faPlay,
} from "@fortawesome/pro-solid-svg-icons";
import { faSearch as faSearchRegular } from "@fortawesome/pro-regular-svg-icons";
import {faTwitter, faYoutube, faLinkedin} from "@fortawesome/free-brands-svg-icons";

export function fontawesomeSetup() {
	library.add(
		faAd,
		faBars,
		faCar,
		faCarCrash,
		faChartPie,
		faCheck,
		faCheckCircle,
		faChevronDown,
		faChevronUp,
		faCog,
		faCross,
		faDolly,
		faExclamationCircle,
		faExternalLink,
		faFileExcel,
		faInfoCircle,
		faMedal,
		faMinus,
		faArrowToRight,
		faPodcast,
		faSearch,
		faSearchRegular,
		faShield,
		faSpinner,
		faTimes,
		faTrash,
		faExclamationCircle,
		faTimesCircle,
		faUser,
		faPlay,
	);

	// brand icons
	library.add(faTwitter, faYoutube, faLinkedin);
}
