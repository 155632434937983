import {useRef, useState, useLayoutEffect} from "react";
import style from "./top.header.module.scss";
import {useKeyboardNavigation as keyboardNavigation} from "wcag-tools";
import logoImg from "../assets/ciht-logo.png";
import {observer} from "mobx-react";
import {useAppStore} from "./app.store.consumer";
import {Link} from "react-router-dom";
import {AppStore} from "../stores/app.store";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {RouteConstants} from "../resources/route-constants";
import {CihtOrgRouteConstants} from "../resources/ciht-org-route-constants";
import ReactDOM from "react-dom";
import {LoginButton} from "./login.button";
import {Search} from "./search";
import {TopHeaderMobile} from "./top.header.mobile";

const hasCpdReadAccess = (appStore: AppStore) => appStore.authStore.hasCpdReadAccess;

type HeaderSubmenuItem = {
	id: string;
	label: string;
	href: string;
	isVisible?: (appStore: AppStore) => boolean;
};

type HeaderSubmenuCategory = {
	id: string;
	title: string;
	items: HeaderSubmenuItem[];
	isVisible?: (appStore: AppStore) => boolean;
};

type HeaderMenuItem = {
	id: string;
	topText: string;
	bottomText: string;
	submenu?: {
		categories: HeaderSubmenuCategory[];
		gridLayout: string;
	};
	href?: string;
	externalHref?: boolean;
	accentColor: string;
	isVisible?: (appStore: AppStore) => boolean;
};

export const getMenu = (baseUrl: string): HeaderMenuItem[] => [
	{
		id: "learn-more",
		topText: "Learn more",
		bottomText: "About CIHT",
		accentColor: "#039DA5",
		href: CihtOrgRouteConstants.getLearnMoreAboutCiht(baseUrl),
		externalHref: true,
	},
	{
		id: "ciht-events",
		topText: "CIHT",
		bottomText: "Events",
		accentColor: "#e03e52",
		href: CihtOrgRouteConstants.getCihtEvents(baseUrl),
		externalHref: true,
	},
	{
		id: "prof-dev",
		topText: "Professional",
		bottomText: "Development",
		accentColor: "#84329B",
		href: CihtOrgRouteConstants.getProfessionalDevelopment(baseUrl),
		externalHref: true,
	},
	{
		id: "knowledge-res",
		topText: "Knowledge &",
		bottomText: "Resources",
		accentColor: "#ed8b00",
		href: CihtOrgRouteConstants.getKnowledgeAndResources(baseUrl),
		externalHref: true,
	},
	{
		id: "become-member",
		topText: "Become a",
		bottomText: "Member",
		accentColor: "#003087",
		href: CihtOrgRouteConstants.getBecomeAMember(baseUrl),
		externalHref: true,
	},
	{
		id: "members-area",
		topText: "Members",
		bottomText: "Area",
		accentColor: "#003087",
		href: CihtOrgRouteConstants.getMembersArea(baseUrl),
		externalHref: true,
	},
	{
		id: "my-dashboard",
		topText: "CIHT",
		bottomText: "Learn",
		accentColor: "#003087",
		submenu: {
			gridLayout: "'dlp cpd'",
			categories: [
				{
					id: "dlp",
					title: "CIHT Learn",
					items: [
						{
							id: "dashboard",
							href: RouteConstants.dlpDashboard,
							label: "Dashboard",
						},
						{
							id: "courses",
							href: "/dlp/courses/all",
							label: "Course Catalogue",
						},
						{
							id: "achievements",
							href: RouteConstants.dlpAchievements,
							label: "My Learning",
							isVisible: appStore => appStore.userStore.isLoggedIn,
						},
					],
				},
				{
					id: "cpd",
					title: "Continuing Professional Development (CPD)",
					items: [
						{
							id: "dev",
							href: RouteConstants.cpdDevelopment,
							label: "My Development Plan",
							isVisible: hasCpdReadAccess,
						},
						//{
						//	id: "devCreate",
						//	href: RouteConstants.cpdCreateGoal,
						//	label: "Create Development Goal",
						//	isVisible: hasCpdAccess,
						//},
						{
							id: "dashboard",
							href: RouteConstants.cpdDashboard,
							label: "My CPD Dashboard",
							isVisible: hasCpdReadAccess,
						},

						//{
						//	id: "activityCreate",
						//	href: RouteConstants.cpdCreateActivity,
						//	label: "Create CPD Activity",
						//	isVisible: hasCpdAccess,
						//},
						{
							id: "swot",
							href: RouteConstants.cpdSwot,
							label: "Personal SWOT",
							isVisible: hasCpdReadAccess,
						},
					],
				},
			],
		},
	},
];

const useOpenedMenuItem = () => {
	const [openedMenuItem, setOpenedMenuItem] = useState<string | null>(null);
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

	return {
		openedMenuItem,
		openMenu(id: string) {
			setOpenedMenuItem(id);
		},
		toggleMenu(id: string) {
			setOpenedMenuItem(openedMenuItemId => {
				if (openedMenuItemId === id) {
					return null;
				} else {
					return id;
				}
			});
		},
		closeMenu() {
			setOpenedMenuItem(null);
		},
		isModalVisible,
		setIsModalVisible,
	};
};

const MenuOpenHoverTimeout = 250;

function useScrollPosition() {
	const [scrollPosition, setPosition] = useState(0);

	useLayoutEffect(() => {
		function updatePosition() {
			setPosition(window.pageYOffset);
		}

		window.addEventListener("scroll", updatePosition);

		updatePosition();

		return () => window.removeEventListener("scroll", updatePosition);
	}, []);

	return scrollPosition;
}

export const TopHeader = observer(() => {
	const appStore = useAppStore();
	const {toggleMenu, openedMenuItem, closeMenu, isModalVisible, setIsModalVisible, openMenu} = useOpenedMenuItem();
	const menuOpenTimeoutRef = useRef(null);

	const scrollPosition = useScrollPosition();

	function clearMenuItemTimeout() {
		if (!menuOpenTimeoutRef.current) {
			return;
		}

		clearTimeout(menuOpenTimeoutRef.current);
		menuOpenTimeoutRef.current = null;
	}

	function onMenuItemClicked(menuItemId: string) {
		setIsModalVisible(openedMenuItem === null);
		toggleMenu(menuItemId);
		clearMenuItemTimeout();
	}

	function onMenuItemHover(menuItemId: string) {
		setIsModalVisible(true);

		clearMenuItemTimeout();

		closeMenu();
		menuOpenTimeoutRef.current = setTimeout(() => {
			openMenu(menuItemId);
		}, MenuOpenHoverTimeout);
	}

	function onMouseLeave() {
		setIsModalVisible(false);
		closeMenu();

		clearMenuItemTimeout();
	}

	const menu = getMenu(appStore.configuration.cihtSiteUrl);

	return (
		<div className={`${style.wrapper} ${scrollPosition !== 0 && style.shadow}`}>
			{isModalVisible && ReactDOM.createPortal(<div className="topmenu-modal open" />, document.body)}
			<header className={style.topHeader}>
				<div className={style.logo}>
					<img src={logoImg} alt="CIHT logo" />
				</div>
				<nav onMouseLeave={onMouseLeave}>
					<div className={style.menuContainer}>
						{menu.map(
							item =>
								(!item.isVisible || item.isVisible(appStore)) && (
									<div
										key={item.id}
										className={style.menuItem}
										onMouseEnter={() => {
											if (item.submenu) {
												onMenuItemHover(item.id);
											} else {
												onMouseLeave();
											}
										}}
									>
										<a
											href={item.href ?? "/#"}
											target="_self"
											onClick={event => {
												if (!item.href) {
													event.preventDefault();
												}

												if (item.submenu) {
													onMenuItemClicked(item.id);
												}
											}}
											{...keyboardNavigation({clickable: true})}
										>
											<span className={style.top}>{item.topText}</span>
											<span className={style.bottom}>
												{item.bottomText}
												{item.submenu && <FontAwesomeIcon transform="down-2 right-4" icon={["fas", "chevron-down"]} />}
											</span>
										</a>
										<div className="topmenu-modal noBackground">
											<div className={style.dropdown}>
												<div
													className={`${style.container} ${openedMenuItem === item.id ? style.open : ""}`}
													style={{gridTemplateAreas: item.submenu?.gridLayout ?? ""}}
												>
													{item.submenu &&
														item.submenu.categories.map(
															category =>
																(!category.isVisible || category.isVisible(appStore)) &&
																category.items &&
																category.items.length > 0 &&
																category.items.some(item => !item.isVisible || item.isVisible(appStore)) && (
																	<div
																		key={category.id}
																		style={{gridArea: category.id, borderColor: item.accentColor}}
																		className={style.category}
																	>
																		<span>{category.title}</span>
																		{category.items.map(
																			cItem =>
																				(!cItem.isVisible || cItem.isVisible(appStore)) && (
																					<Link onClick={() => closeMenu()} key={cItem.id} to={cItem.href}>
																						{cItem.label}
																					</Link>
																				),
																		)}
																	</div>
																),
														)}
												</div>
											</div>
										</div>
									</div>
								),
						)}
					</div>
				</nav>
				<div className={style.rightMenu}>
					<Search />
					<LoginButton />
					<TopHeaderMobile />
				</div>
			</header>
		</div>
	);
});
