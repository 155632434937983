import React from "react";
import {AppStore, IAppStore} from "../stores/app.store";
import {observer} from "mobx-react";
import {AppContext} from "./mainframe";

/** Decorator to be used for injecting AppStore to React component. */
export const getAppStore = () => {
	return <TAppStore extends IAppStore, T extends {appStore?: TAppStore}, C extends React.ComponentType<T> | {(props: T): JSX.Element}>(Component: C): C => {
		const ObserverComponent: any = observer(Component);
		const Wrapper: React.FC<T> = (props: T) => {
			const appStore = React.useContext(AppContext);
			return <ObserverComponent appStore={appStore} {...props} />;
		};

		return Wrapper as any as C;
	};
};

export type AppContextProviderType = {
	appContext: React.Context<IAppStore>;
};

export const AppContextProvider: AppContextProviderType = {
	appContext: null,
};

export const useAppStoreContext = <TAppStore extends IAppStore>() => React.useContext(AppContextProvider.appContext) as TAppStore;
export const useAppStore = () => useAppStoreContext<AppStore>();
