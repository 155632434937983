import {observer} from "mobx-react";
import style from "./top.header.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getMenu} from "./top.header";
import {useAppStore} from "./app.store.consumer";
import {useState} from "react";
import {Link} from "react-router-dom";

export const TopHeaderMobile = observer(() => {
	const appStore = useAppStore();
	const menu = getMenu(appStore.configuration.cihtSiteUrl);
	const [isOpen, setIsOpen] = useState(false);
	const [openedMenuItem, setOpenedMenuItem] = useState<string>("");

	return (
		<div className={style.mobileMenu}>
			<div className={style.hamburgerIcon} onClick={() => setIsOpen(!isOpen)}>
				<FontAwesomeIcon icon={["fas", isOpen ? "times" : "bars"]} />
			</div>
			{isOpen && (
				<div className={style.mobileMenuContainer}>
					{menu.map(item => (
						<div key={item.id} className={style.menuItem}>
							<a
								href={item.href ?? "/#"}
								onClick={e => {
									if (item.href) {
										setIsOpen(false);
										return;
									}

									e.preventDefault();
									if (openedMenuItem === item.id) {
										setOpenedMenuItem("");
									} else setOpenedMenuItem(item.id);
								}}
							>
								{item.topText} {item.bottomText}
								{item.submenu && <FontAwesomeIcon transform="down-1 right-4 shrink-4" icon={["fas", "chevron-down"]} />}
							</a>
							{item.id === openedMenuItem &&
								item.submenu &&
								item.submenu.categories
									.filter(
										category =>
											(!category.isVisible || category.isVisible(appStore)) &&
											category.items.length > 0 &&
											category.items.some(item => !item.isVisible || item.isVisible(appStore)),
									)
									.map(category => (
										<div key={category.id} className={style.subMenu}>
											<div>{category.title}</div>
											{category.items.map(
												categoryItem =>
													(!categoryItem.isVisible || categoryItem.isVisible(appStore)) && (
														<Link
															onClick={() => {
																setIsOpen(false);
																setOpenedMenuItem("");
															}}
															to={categoryItem.href}
															key={categoryItem.id}
														>
															{categoryItem.label}
														</Link>
													),
											)}
										</div>
									))}
						</div>
					))}
				</div>
			)}
		</div>
	);
});
